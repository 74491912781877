<template>
  <div class="email" style="padding: 5px 10px 10px 10px">
    <div class="header">
      {{ $t("add_confidential_email.add_confidential_email") }}
    </div>
    <div v-if="!isLoading" class="testingScroll">
      <table>
        <tr>
          <th>{{ $t("admin.email") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr>
          <td>
            <div v-for="(item, index) in email" :key="index">
              {{ item }}
            </div>
          </td>
          <td>
            <b-button @click="(modalShow = !modalShow), editEmail(email)">
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="9"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal
      id="modal-lg-2"
      v-model="modalShow"
      size="lg"
      hide-footer
      v-if="edit"
    >
      <div class="update">
        <div class="text_editor" v-if="email != 0">
          <label for="">{{ $t("admin.add_email_confidential") }}</label>
          <div v-for="(item, index) in email" :key="index" class="d-flex">
            <div class="choosed_keywords">
              {{ item }}
            </div>
            <i
              class="fal fa-times-square"
              style="cursor: pointer; margin-left: 20px; font-size: 20px"
              @click="delete_keywords(index)"
            ></i>
          </div>
        </div>
        <div class="text_editor add_comp">
          <label style="margin-right: 10px" for="">{{
            $t("admin.email_confidential")
          }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              style="margin-right: 10px"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square" @click="delete_comp"></i>
          </div>
        </div>
        <button
          type="submit"
          class="submit mt-3 btn btn-primary"
          @click="UpdateEmail(), emptyValues($event)"
        >
          <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getTeam",
  components: {
    spinner,
  },
  data() {
    return {
      modalShow: false,
      edit: false,
      email: [],
      components: [{ comp: "comp" }],
      isLoading: false,
      server_url: env.server_url,
    };
  },
  mounted() {
    this.getConfidentialEmail();
  },
  methods: {
    getConfidentialEmail() {
      axios.get(`${env.host}/get/informations/email`).then((res) => {
        this.email = res.data.item;
      });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.email.splice(index, 1);
      }
    },
    get_value(e) {
      this.email.push(e.target.value);
    },
    editEmail(email) {
      this.edit = true;
      this.email = email;
    },
    UpdateEmail() {
      this.isLoading = true;
      axios
        .post(`${env.host}/change/confidentialInformation/email`, {
          email: this.email,
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getContactInfo();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.components = [{ comp: "comp" }];
    },
  },
};
</script>

<style scoped>
.header {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
  color: white;
  margin-bottom: 10px;
}
.email {
  width: 100%;
  overflow: hidden;
}
.testingScroll {
  width: 100% !important;
  max-width: 1400px;
  min-height: calc(100vh - 160px);

  max-height: calc(100vh - 200px) !important;
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

label {
  font-family: mtavruliBOLD;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
:v-deep .btn-danger {
  border-radius: 0px !important;
}
.delete {
  background: #f02849;
  border-radius: 0px !important;
}
i {
  cursor: pointer;
}
label {
  margin-bottom: 10px;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
input,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  padding-left: 10px;
  height: 40px;
  margin-bottom: 8px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
